<template>
  <div style="text-align: left;">
    <v-btn icon @click="getAssets">
      <v-icon>mdi-reload</v-icon>
    </v-btn>
      <Gallery v-if="ready" :search="search" :assetTree="assetTree" :assets="assets" @selectItem="selectItem"/>
      <!-- <v-treeview 
        v-if="ready"
        dense 
        hoverable 
        open-on-click 
        item-text="Key"
        item-key="Key" 
        :items="assetTree" 
        :open-all="true"
        :search="search"
        :filter="_filter"
      >
          <template v-slot:prepend="{ item, open }">
              <v-icon @click="selectItem(item)">
                {{ open && item.children.length>0 ? 'mdi-folder-open' : 'mdi-web' }}
              </v-icon>
          </template>

          <template v-slot:label="{item}">
              <span v-html="item.Key" @click="selectItem(item)"/>
          </template>

          <template v-slot:append="{ item }">
              <v-btn text v-if="item.asset" @click="selectItem(item)">SELECT</v-btn>
          </template>
      </v-treeview> -->
      <div v-else>...loading...</div>
    
  </div>
</template>

<script>
import Gallery from './Gallery.vue'
export default {
  components: {
    Gallery
  },
  created: function(){
    this.getAssets()
  },
  props: {
    search: {
      type: String,
      required: false
    },
    openAll: {
      type: Boolean,
      required: false,
      default: true
    },
    base: {
      type: String,
      required: false,
      default: 'uploads'
    },
    endPoint: {
      type: String,
      required: false,
      default: '/api/admin/assets'
    }
  },
  data: function(){
    return {
      bucket: null,
      assets: [],
      assetTree: [],
      caseSensitive: false,
      ready: false
    }
  },
  methods: {
    getAssets: async function(){
        this.ready = false
        let response = await this.sendRequest('get',this.endPoint)
        if(response.status==200){
            this.assets = response.data.contents
            this.bucket = response.data.bucket
            this.$emit('setBucket',this.bucket)
            this.rebuildTree()
        }
        this.ready = true
    },
    rebuildTree: function(){
      this.assetTree = this.buildTree(this.assets, 'Key', '/' ,'children')
    },
    buildTree: function(flatArray, keyField, delimiter, childField)  {
      let tree = [
        {
          Key: this.base,
          children: []
        }
      ]
      
      function insertAsset(tree, node, keyField, delimiter, childField){
        function searchTree(tree, key){
          let match = tree.filter((item)=>{return item[keyField]==key})

          if(match.length==0){
            for(let i=0; i<tree.length; i++){
              let branch = tree[i]
              let result = searchTree(branch[childField], key)
              if(result){
                return result
              }
            }
          }

          if(match.length==1){
            return match[0]
          }

          return false

        }

        let keyString = node[keyField].replace(/\/$/, "")
        let stack = keyString.split(delimiter)

        let trace = []
        for(let depth=0; depth<stack.length; depth++){
          let key = stack[depth]
          let result = searchTree(tree, key, keyField)
          if(result){
            trace.push(result)
          }else{
            let record = {}
            record[keyField] = key
            record[childField] = []
            if(depth==stack.length-1){
              record.asset = node
            }
            trace[trace.length-1][childField].push(record)
            insertAsset(tree, node, keyField, delimiter, childField)
            break;
          }
        }

      }

      function findNode(array, node, keyField, delimiter, childField, search=null, trace=null){
        let keyString = node[keyField].replace(/\/$/, "")
        let searchStack
        if(!search){
          searchStack = keyString.split(delimiter)
        }else{
          searchStack = search.searchStack
        }

        let result
        for(let stackDepth = search ? search.stackDepth+1 : 0; stackDepth<searchStack.length; stackDepth++){
          let key = searchStack[stackDepth]
          if(key.length>0){
            for(let i=0; i<array.length; i++){
              let record = array[i]
              let recordKey = record[keyField]
              if(recordKey == key){
                if(stackDepth==searchStack.length-1){
                  return record
                }else if(record.children){
                  result = findNode(record.children, node, keyField, delimiter, childField, {searchStack, stackDepth}, trace!=null ? `${trace}[${i}]` : `[${i}]`)
                }else{
                  return false
                }
              }
            }
          }
        }
        return result
      }


      //Add each S3 asset
      for(let i=0; i<flatArray.length; i++){
        let node = flatArray[i]
        let result = findNode(tree, node, keyField, delimiter, childField)
        if(!result){
          insertAsset(tree, node, keyField, delimiter, childField)
        }
      }

      return tree

    },
    selectItem: function(item){
      // console.log('selectAsset',item)
      if(item.asset){
        console.log('Browser emit: selectAsset',{item, assetTree: this.assetTree})
        this.$emit('selectAsset',item.asset)
        this.$emit('input',item.asset)
      }else{
        this.$emit('selectAsset',item)
        this.$emit('input',item)

      }
    }
  },
  computed: {
    // _assets: function(){
    //     function template(){
    //         return {
    //             _folders: {},
    //             _assets: []
    //         }
    //     }
    //     let stats = {
    //         folders: 0,
    //         files: 0,
    //         size: 0,
    //         types: {}
    //     }
    //     let output = {
    //         uploads: template()
    //     }
    //     let assets = this.assets

    //     console.log('_assets',assets)
        
    //     for(let i=0; i<assets.length; i++){
    //         let asset = assets[i]
    //         let trace = asset.Key.split('/')
    //         let isFolder = trace[trace.length-1]==''
    //         if(isFolder){
    //             stats.folders++
    //             let path = ''
    //             for(let t=0; t<trace.length-1; t++){
    //                 let value = trace[t]
    //                 let container = path+'.'+value
    //                 let target = eval("output"+container)
    //                 path += '.'+value+'._folders'
    //                 if(target==undefined){
    //                     console.log('creating container', container)
    //                     eval("output"+container+"=template()")
    //                 }
    //             }
    //         }
    //     }

        
    //     for(let i=0; i<assets.length; i++){
    //         let asset = assets[i]
    //         let trace = asset.Key.split('/')
    //         let isFolder = trace[trace.length-1]==''
    //         if(!isFolder){
    //             stats.files++
    //             stats.size+=asset.Size
    //             let parts = trace[trace.length-1].split('.')
    //             let type = parts[parts.length-1].toUpperCase()
    //             if(!stats.types[type]){
    //                 stats.types[type] = 0
    //             }else{
    //                 stats.types[type]++
    //             }
    //             let path = ''
    //             for(let t=0; t<trace.length-1; t++){
    //                 let value = trace[t]
    //                 let container = path+'.'+value
    //                 let target = eval("output"+container)
    //                 path += '.'+value+'._folders'
    //                 if(!target){
    //                     eval("output"+container+"=template()")
    //                     target = eval("output"+container)
    //                 }
    //                 target._assets.push(asset)
    //             }
    //         }
    //     }

    //     return {
    //         stats,
    //         assets: output
    //     }
    // },
    _duplicates: function(){
        let output = {}
        let assets = this.assets
        for(let i=0; i<assets.length; i++){
            let asset = assets[i]
            let trace = asset.Key.split('/')
            let isFolder = trace[trace.length-1]==''
            if(!isFolder && !output[asset.ETag]){
                let matches = assets.filter((item)=>{return item.ETag==asset.ETag})
                if(matches.length>1){
                    output[asset.ETag] = matches
                }
            }
        }

        return output
    },
    _filter: function(){
        return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    }
  }
}
</script>

<style>

</style>