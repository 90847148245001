<template>
  <div>
    <v-container v-if="labels" fluid>
        <v-row style="text-align: left;">
            <v-col>
                <v-text-field label="New Label:  No spaces / Use '.' to separate levels" outlined v-model="newKey" :append-icon="newKey && newKey.length>0 ? 'mdi-new-box' : ''" @click:append="newLabel(newKey)" clearable/>
                <LabelSelector :labels="labels" @editLabel="navEdit" @newLabel="newLabel" @deleteLabel="deleteLabel"/>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="ui.editor" persistent fullscreen>
        <v-card v-if="ui.editor">
            <v-card-title>
                {{editing.key}}
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-for="language in languages" :key="`editor-${language}`">
                            <TinyEditor v-model="editing.text[language]" :init="tinyConfig.init" :api-key="tinyConfig.apiKey"/>
                            <!-- <v-textarea outlined v-model="editing.text[language]" :label="language"/> -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="closeEditor">
                    Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="saveLabel">
                    save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LabelSelector from './LabelEditor/LabelSelector.vue'
import TinyEditor from '@tinymce/tinymce-vue'
export default {
    name: "LabelEditor",
    components: {
        LabelSelector,
        TinyEditor
    },
    created: async function(){
        this.tinyConfig.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
        await this.getLabels()
        if(this.index){
            this.editLabel(this.index)
        }
    },
    data: function(){
        return {
            ui: {
                editor: false
            },
            tinyConfig: {
                init: {
                    selector: 'textarea',
                    plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                    toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
                    height: "800",
                    setup: function(editor){
                        // editor.ui.registry.addButton('customExitButton', {
                        //     text: 'Exit',
                        //     onAction: function (_) {
                        //         GuideEditor.exit()
                        //     }
                        // });
                        // editor.ui.registry.addButton('customSaveButton', {
                        //     text: 'Save',
                        //     onAction: function (_) {
                        //         GuideEditor.save()
                        //     }
                        // });
                        // editor.ui.registry.addButton('customPreviewButton', {
                        //     text: 'Preview',
                        //     onAction: function (_) {  
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.preview = true
                        //     }
                        // });
                        // editor.ui.registry.addButton('customGuideDetails', {
                        //     text: 'Guide Details',
                        //     onAction: function (_) {  
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.guideDetails = true
                        //     }
                        // });
                        // editor.ui.registry.addButton('customSelectTopicsButton', {
                        //     text: 'Select Topic(s)',
                        //     onAction: function (_) {  
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.selectTopics = true
                        //     }
                        // });
                        
                        editor.on('init', function(e) {
                            // editor.execCommand('mceFullScreen');
                            // if(!GuideEditor.guide.meta.id){
                            //     GuideEditor.state.guideDetails = true
                            // }
                        });                        
                    }
                },
                apiKey: null
            },
            labels: null,
            editing: null,
            newKey: null
        }
    },
    methods: {
        getLabels: async function(){
            let response = await this.sendRequest('get','/api/label')
            if(response.status==200){
                this.labels = response.data
                this.$store.commit('labels',response.data)
            }
        },
        navEdit: function(key){
            if(!key){
                key = this.newKey  
            }

            this.$router.push({name: 'LabelAdminEditor', params:{index:key}})
        },
        newLabel: function(key){
            this.navEdit(key)
        },
        editLabel: function(key){
            let languages = this.languages
            let labels = this.labels
            let trace = key.split('.')
            let path = ''
            let found = true
            for(let i=0; i<trace.length; i++){
                let node = trace[i]
                path += i==0 ? node : `.${node}`
                let check = eval(`labels.${path}`)
                if(!check){
                    found = false
                    break;
                }
            }

            let editing = {
                key: key,
                text: {}
            }

            if(found){
                let target = eval(`labels.${key}`)
                editing.text = target._text
            }else{
                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    editing.text[language] = null
                }
            }

            editing = JSON.stringify(editing)
            this.editing = JSON.parse(editing)
            this.ui.editor = true
        },
        closeEditor: function(){
            this.ui.editor = false
            this.editing = null
            this.$router.push({name:'LabelAdmin'})
        },
        saveLabel: async function(){
            let response = await this.sendRequest('post','/api/admin/label',this.editing)
            if(response.status==200){
                this.closeEditor()
                this.getLabels()
            }
        },
        deleteLabel: async function(key){
            if(confirm('Delete this label?\r\n'+key)){
                let response = await this.sendRequest('delete','/api/admin/label',{key})
                if(response.status==200){
                    this.closeEditor()
                    this.getLabels()
                }
            }

        }
    },
    computed: {
        languages: function(){
            return this.$store.getters.languages
        },
        index: function(){
            return this.$route.params.index ? this.$route.params.index : null
        }
    },
    watch: {
        index: function(){
            let index = this.index
            if(index){
                this.editLabel(index)
            }
        }
    }
}
</script>

<style>

</style>