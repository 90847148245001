<template>
  <div id="Posts" style="text-align: left;">
    <v-list v-if="posts.length>0">
        <template v-for="(post, index) in posts">
            <v-list-item v-if="userMap[post.user_id]" :key="`post-${index}`" class="post" :class="[`state-${post.state}`,{'editing':isEditing(post)}, {'editing-but-not-this':editing && editing.id && !isEditing(post)}, {'my-post':isMyPost(post)}, {'others-post':!isMyPost(post)}]">
                <v-list-item-action class='speechBubble'>
                    <v-icon :color="post.user_id==user_id ? 'green' : 'blue'">
                        mdi-chat
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        {{post.message}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span>{{fullName(userMap[post.user_id])}} - {{Intl.DateTimeFormat('en-US', {dateStyle: 'medium', timeStyle: 'long'}).format(new Date(post.created))}}</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class='editActions'>
                    <template v-if="isAdmin && $route.name=='Admin'">
                        <v-btn v-if="post.state!='deleted'" icon small @click="$emit('delete',post)">
                            <v-icon color="error">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                        <v-btn v-else icon small @click="$emit('restore',post)">
                            <v-icon color="blue">
                                mdi-backup-restore
                            </v-icon>
                        </v-btn>
                    </template>

                    <template v-else-if="isMyPost(post)">
                        <div v-if="!isEditing(post)">
                            <v-btn icon small @click="$emit('delete',post)">
                                <v-icon color="error">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                            <v-btn icon small @click="$emit('edit',post)">
                                <v-icon color="green">
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-btn icon small @click="$emit('cancelEdit')" v-else>
                            Cancel
                        </v-btn>
                    </template>
                </v-list-item-action>
            </v-list-item>
        </template>
    </v-list>
    <div v-else>
        <center class='postPrompt'>Be the first to post something</center>
    </div>
  </div>
</template>

<script>
export default {
    name: "DiscussionFormPosts",
    props: {
        userMap: {
            type: Object,
            required: true
        },
        editing: {
            type: Object,
            required: false
        },
        posts: {
            type: Array,
            reuqired: true
        }
    },
    methods: {
        isMyPost: function(post){
            return post.isMine
            // if(post){
            //     return post.user_id == this.user_id
            // }else{
            //     return false
            // }
        },
        isEditing: function(post){
            let editing = this.editing
            if(editing){
                let isEditing = editing.id == post.id
                return isEditing
            }else{
                return false
            }

        },
        fullName: function(user){
            let given_name = user.Attributes.find((item)=>item.Name=='given_name').Value
            let family_name = user.Attributes.find((item)=>item.Name=='family_name').Value

            return `${given_name} ${family_name}`
        }
    },
    computed: {
        user_id: function(){
            return this.$store.getters.user.username
        },
        isAdmin: function(){
            let allowed = [
                'SuperAdmin',
                'admin',
                'staff'
            ]
            let groups = this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] : []
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allowed.includes(group)){
                    return true
                }
            }
            return false
        }
    }
}
</script>

<style scoped lang="scss">

#Posts {

    .post{
        border-bottom: 1px solid whitesmoke;

        @media(max-width: 767px){ // sm < 768
            padding: 0;
        }

        &.state-deleted{
            background-color: lightgrey;
            color: grey !important;
        }

        &.editing-but-not-this{
            background-color: lightgrey;
            opacity: 0.7;
        }

        &.my-post{
            color: blue;
        }

        &.others-post .v-icon{
            transform: scaleX(-1)
        }

        .speechBubble {
            @media(max-width: 575px){ // sm < 768
                margin-right: 8px;

                i {
                    font-size: 18px;
                }
            }
        }

        .v-list-item__title,
        .v-list-item__subtitle {
            white-space: normal;
        }

        .v-list-item__title {
            @media(max-width: 575px){ // sm < 768
                font-size: 14px;
            }
        }

        .v-list-item__subtitle {
            font-size: 12px;
            color: $grey_dark;
            margin-top: 8px;

            @media(max-width: 575px){ // sm < 768
                font-size: 10px;
            }
        }

        .editActions {

            i {
                @media(max-width: 575px){ // xs < 576
                    font-size: 22px;
                }
            }
        }
    }

    .postPrompt {
        margin-top: 16px;
    }
}

</style>
