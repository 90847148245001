<template>
  <div>
    <v-card>
        <v-card-text>   
            <template  v-if="ready">         
                <!-- Discussion Form Info -->
                <v-container>
                    <v-row>
                        <v-col style="text-align: left;">
                            <v-switch v-model="form.publish" true-value="Y" false-value="N" label="Publish"/>
                            <v-text-field v-model="form.title[language]" label="Title"/>
                            <v-text-field v-model="form.subtitle[language]" label="Subtitle"/>
                            
                            <h3>Associated Meetings</h3>
                            <v-list dense>
                                <v-list-item v-for="meeting in meetings" :key="`topic-event-${meeting.id}`">
                                    <v-checkbox  v-model="form.events" :value="meeting.id" :label="meeting.title[language]"/>
                                </v-list-item>
                            </v-list>

                            <h3>Restricted To: </h3>
                            <v-list dense>
                                <template v-for="(group) in userData.groups">
                                    <v-list-item v-if="!['SuperAdmin','admin','staff','tester'].includes(group.GroupName)" :key="`event-restriction-${group.GroupName}`">
                                        <v-checkbox :value="group.GroupName" :label="group.GroupName" v-model="form.restrictedTo"/>
                                    </v-list-item>
                                </template>
                            </v-list>


                            <h3>Tags</h3>
                            <v-textarea v-model="temp.tags[language]" @keyup="setTags" label="Tags, one per line"/>
                        </v-col>
                    </v-row>
                </v-container>

                <!--MEETING MATERIALS-->
                <MeetingMaterials v-model="form"/>
                <!-- <v-container fluid>
                    <v-row>
                        <v-col cols="4">
                            <h3>Meeting Materials</h3>
                            <v-list dense>
                                <draggable v-model="form.resources">
                                    <v-list-item v-for="(resource, index) in form.resources" :key="`resource-${index}`" style="border: 1px solid whitesmoke;">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-text-field v-model="resource.label" label="Label"/>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{resource.asset ? resource.asset.Key : '---'}}
                                            </v-list-item-subtitle>
                                            Restricted To: 
                                            <v-checkbox v-for="(key) in ['faculty','panel','speaker']" :key="`restriction-check-${index}-${key}`" :value="key" :label="key" v-model="resource.restrictedTo" style="margin-right: 15px;"/>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <div>
                                                <v-btn icon small @click="form.resources.splice(index,1)">
                                                    <v-icon color="error">
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-list-item-action>
                                    </v-list-item>
                                </draggable>
                            </v-list>

                        </v-col>
                        <v-col cols="8">
                            <h3>Available Materials</h3>
                            <template  v-if="ui.view=='Uploader'">
                                <v-btn text small @click="ui.view='Browser'">Browser</v-btn>
                                <Uploader/>
                            </template>

                            <template v-if="ui.view=='Browser'">
                                <v-btn text small @click="ui.view='Uploader'">Uploader</v-btn>
                                <Browser  @selectAsset="addResource"/>
                            </template>
                        </v-col>
                    </v-row>
                </v-container> -->
            </template>
            <template v-else>...loading...</template>
        </v-card-text>

        <v-card-actions>
            <v-btn color="warning" @click="$emit('cancel')">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="save">Save</v-btn>
        </v-card-actions>
    </v-card>



  </div>
</template>

<script>
import MeetingMaterials from '@/components/Admin/MeetingMaterials'
import MeetingSystem from '@/components/Event/eventSystem.js'
export default {
    name: "TopicForm",
    components: {
        MeetingMaterials
    },
    created: async function(){
        this.initForm()
        this.meetingsAPI = new MeetingSystem(this)
        await this.getMeetings()
        await this.getUsers()
        this.ready = true
    },
    data: function(){
        return {
            ready: false,
            ui: {
                view: 'Browser'
            },
            form: {},
            meetings: [],
            userData: {},
            temp: {
                tags: {}
            }
        }
    },
    methods: {
        async getUsers(){
            let response = await this.sendRequest('get',`/api/admin/users`)
            if(response.status==200){
                let data = response.data.userData
                for(let key in data){
                    this.userData[key] = data[key]
                }
            }
        },
        initForm: async function(){
            let model = JSON.stringify(this.model)
            this.form = JSON.parse(model)

            for(let language in this.form.tags){
                let tags = this.form.tags[language]


                let tempTags = ''
                for(let i=0; i<tags.length; i++){
                    let tag = tags[i]
                    tempTags+=tag+'\n'
                }
                this.temp.tags[language] = tempTags
            }
        },
        getMeetings: async function(){
            this.meetings = await this.meetingsAPI.getAllMeetings()
        },
        setTags: function(){
            let language = this.language
            let tags = this.temp.tags[language]
            let output = tags.split('\n')
            this.form.tags[language] = output
        },
        addResource: function(asset){
            let record = {
                label: null,
                asset,
                restrictedTo: []
            }
            this.form.resources.push(record)
        },
        save: function(){
            this.$emit('save',this.form)
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        model: function(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>