<template>
  <div>
    LABEL: {{LabelKey}}
    <!-- v-if="key!=='_text'" :labels="labels"  -->
    <ul>
        <template v-for="(labels, key) in Label">
            <li :key="`${LabelKey}-${key}`">
                {{key}}
                <div v-if="key!='_text'" :labels="labels"/>
                <LabelSelector :labels="labels"/>
            </li>
        </template>
    </ul>
  </div>
</template>

<script>
import LabelSelector from './LabelSelector.vue'
export default {
    name: "LabelItem",
    components: {
        LabelSelector
    },
    props: {
        Label: {
            type: Object,
            required: true
        },
        LabelKey: {
            type: String,
            required: true
        }
    }
}
</script>

<style>

</style>