<template>
<div>
    <v-container fluid>
        <v-row>
            <v-col cols="5">
                <h3>Meeting Materials</h3>
                <v-btn @click="addLink" text>Add Link</v-btn>
                <v-list dense>
                    <draggable v-model="form.resources">
                        <v-list-item v-for="(resource, index) in form.resources" :key="`resource-${index}`" style="border: 1px solid whitesmoke;">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-text-field v-model="resource.label" label="Label"/>
                                    <template v-if="resource.link">
                                        <v-text-field v-model="resource.link.url" label="URL"/>
                                        <v-select v-model="resource.link.icon" :items="[{text:'Word',value:'mdi-file-word-outline'},{text:'Excel',value: 'mdi-microsoft-excel'},{text:'Power Point',value: 'mdi-file-powerpoint-outline'},{text:'Other',value: 'mdi-file'}]" label="Type"/>
                                        <v-icon v-if="resource.link.icon">{{resource.link.icon}}</v-icon>
                                    </template>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{resource.asset ? resource.asset.Key : resource.link ? resource.link.url : '---'}}
                                </v-list-item-subtitle>

                                <template v-if="form.roles">
                                    Restricted To: 
                                    <v-list dense>
                                        <v-list-item v-for="(role, key) in form.roles" :key="`restriction-check-${index}-${key}`">
                                            <v-checkbox dense :value="key" :label="key" v-model="resource.restrictedTo"/>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>
                                    <v-btn icon small @click="removeResource(index)">
                                        <v-icon color="error">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </draggable>
                </v-list>

            </v-col>
            <v-col cols="6">
                <h3>Available Materials</h3>
                <template  v-if="ui.view=='Uploader'">
                    <v-btn text small @click="ui.view='Browser'">Browser</v-btn>
                    <Uploader/>
                </template>

                <template v-if="ui.view=='Browser'">
                    <v-btn text small @click="ui.view='Uploader'">Uploader</v-btn>
                    <Browser  @selectAsset="addResource"/>
                </template>
            </v-col>
        </v-row>
    </v-container>      
</div>
</template>

<script>
import draggable from 'vuedraggable'
import Browser from '@/components/Assets/Browser'
import Uploader from '@/components/Assets/Uploader'
export default {
    name: "MeetingMaterialsAdmin",
    created: function(){
        this.form = this.model
    },
    components: {
        draggable,
        Browser,
        Uploader
    },
    data: function(){
        return {
            ui: {
                view: 'Browser'
            },
            form: {}
        }
    },
    methods: {
        addResource: function(asset){
            let record = {
                label: null,
                asset,
                restrictedTo: []
            }
            this.form.resources.push(record)
        },
        addLink: function(link){
            let record = {
                label: null,
                link: {
                    url: null,
                    icon: null
                },
                restrictedTo: []
            }
            this.form.resources.push(record)
        },
        removeResource(index){
            this.form.resources.splice(index,1)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>