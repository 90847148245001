<template>
  <div v-if="form">
    <v-card>
        <v-card-text>

            <!--USERS-->
            <v-container fluid>
                <v-row v-if="state.userData=='ready'">
                    <v-col cols="4" style="text-align: left;">
                        Users
                        <v-list dense>
                            <v-list-item v-for="user in userData.users" :key="`user-${user.Username}`" style="border: 1px solid lightgrey;">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{getAttribute('given_name',user.Attributes)}} {{getAttribute('family_name',user.Attributes)}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{getAttribute('email',user.Attributes)}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn @click="assignRole(user, key)" small text v-for="(role, key) in form.roles" :key="`role-button-${key}`">
                                        {{key}}
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="8">
                        Roles
                        <v-container fluid>
                            <v-row>
                                <template v-for="group in userData.groups">
                                    <v-col v-if="!avoidRoles.includes(group.GroupName)" :key="`role-${group.GroupName}`">
                                        {{group.GroupName}}
                                        <template v-for="userName in form.roles[group.GroupName]">
                                            <v-chip v-if="userMap[userName]" :key="`role-${group.GroupName}-${userName}`" color="indigo" text-color="white" close close-icon="mdi-delete" @click:close="removeRole(userName, group.GroupName)" style="display: block; margin: 5px;">
                                                <v-icon left>
                                                    mdi-account-circle-outline
                                                </v-icon>
                                                {{getAttribute('given_name',userMap[userName].Attributes)}} {{getAttribute('family_name',userMap[userName].Attributes)}} - {{getAttribute('email',userMap[userName].Attributes)}}
                                            </v-chip>
                                        </template>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col>...loading users...</v-col>
                </v-row>
            </v-container>

            <!--PRE MEETING SURVEYS-->
            <v-container>
                <v-row>
                    <v-col>
                        <h3>Pre-Meeting Surveys</h3>
                    </v-col>
                </v-row>
                <v-row v-if="state.userData=='ready'">
                    <v-col style="text-align: left;">
                        Received Surveys
                        <v-list dense>
                        <template v-for="(submission) in preMeetingAnswers">
                            <v-list-item v-if="submission.event_id==model.id" :key="`premeeting-answer-selector-${submission.id}`">
                                <v-list-item-action>
                                    {{submission.survey_stage}}
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <template v-if="userMap[submission.user_id]">
                                            {{getAttribute('given_name',userMap[submission.user_id].Attributes)}} {{getAttribute('family_name',userMap[submission.user_id].Attributes)}}
                                        </template>
                                        <template v-else>
                                            Removed User: {{submission.user_id}}
                                        </template>

                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{Intl.DateTimeFormat('en-US', {dateStyle: 'long', timeStyle: 'long'}).format(new Date(submission.created))}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="selectSubmission(submission)">
                                        <v-icon>
                                            mdi-eye
                                        </v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col>...loading submissions...</v-col>
                </v-row>
            </v-container>

            <!--POST MEETING SURVEYS-->
            <v-container>
                <v-row>
                    <v-col>
                        <h3>Post-Meeting Surveys</h3>
                    </v-col>
                </v-row>
                <v-row v-if="state.userData=='ready'">
                    <v-col style="text-align: left;">
                        Received Surveys
                        <v-list dense>
                        <template v-for="(submission) in postMeetingAnswers">
                            <v-list-item v-if="submission.event_id==model.id" :key="`postmeeting-answer-selector-${submission.id}`">
                                <v-list-item-action>
                                    {{submission.survey_stage}}
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <template v-if="userMap[submission.user_id]">
                                            {{getAttribute('given_name',userMap[submission.user_id].Attributes)}} {{getAttribute('family_name',userMap[submission.user_id].Attributes)}}
                                        </template>
                                        <template v-else>
                                            Removed User: {{submission.user_id}}
                                        </template>

                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{new Date(submission.created)}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="selectSubmission(submission)">
                                        <v-icon>
                                            mdi-eye
                                        </v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col>...loading submissions...</v-col>
                </v-row>
            </v-container>

            <!--MEETING INFO-->
            <v-container>
                <v-row>
                    <v-col>
                        <v-switch true-value="Y" false-value="N" v-model="form.publish" label="Publish"/>
                        <div style="display:flex">
                            <p style="align-self:center; margin-bottom:0; padding-right: 4px;">Meeting Category:&nbsp;&nbsp;</p>
                            <v-switch class="custom-red" inset true-value="online" false-value="in-person" v-model="form.category" :label="meetingCategoryState"/>
                        </div>
                        <v-text-field v-model="form.title[language]" label="Meeting Title"/>
                        <v-container>
                            <v-row>
                                <v-col><v-select clearable v-model="form.preMeetingSurvey" label="Pre-meeting Survey" :items="questionSetItems"/></v-col>
                                <v-col><v-select clearable v-model="form.postMeetingSurvey" label="Post-meeting Survey" :items="questionSetItems"/></v-col>
                            </v-row>
                        </v-container>
                        <v-text-field v-model="form.meetingID" label="Meeting ID"/>
                        <v-text-field v-model="form.meetingLink" label="Meeting Link"/>
                        <v-text-field v-model="form.meetingPassword" label="Meeting Password"/>
                        <v-textarea v-model="form.agenda[language]" label="Meeting Agenda"/>
                    </v-col>
                </v-row>
            </v-container>

            <!--MEETING MATERIALS-->
            <MeetingMaterials v-model="form"/>

            <!--MEETING START/END TIMES-->
            <v-container>
                <v-row>
                    <v-col>Start Date/Time</v-col>
                    <v-col>End Date/time</v-col>
                </v-row>
                <v-row style="text-align: left;">
                    <v-col>
                        <v-date-picker v-model="temp.start.date"></v-date-picker>
                        <v-time-picker
                            v-model="temp.start.time"
                            format="24hr"
                            landscape
                            />
                    </v-col>
                    <v-col>
                        <v-date-picker v-model="temp.end.date"></v-date-picker>
                        <v-time-picker
                            v-model="temp.end.time"
                            format="24hr"
                            landscape
                            />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h3>Duration: {{duration ? duration : '--'}}min</h3>
                    </v-col>
                </v-row>
            </v-container>

        </v-card-text>
        <v-card-actions>
            <v-btn color="warning" @click="$emit('cancel')">
                Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="save" color="success" :disabled="!allowSave">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="ui.reviewSubmission">
        <v-card v-if="ui.reviewSubmission">
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col style="text-align: left;">
                            <v-list dense v-for="question in questions.data" :key="`question-${question.id}`">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{question.body.text[language]}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        ID: {{question.id}}
                                    </v-list-item-subtitle>
                                    <v-list dense>
                                        <v-list-item v-for="group in question.groups" :key="`question-${question.id}-group-${group.id}`">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{group.text[language]}} Type: [{{group.type}}]
                                                </v-list-item-title>
                                                <v-list dense>
                                                    <v-list-item style="border-bottom: 1px solid lightgrey;" v-for="choice in group.choices" :key="`question-${question.id}-group-${group.id}-choice-${choice.id}`">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{choice.text[language]}}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <template v-if="selectedSubmission.data.questions[question.id]?.group[group.id]?.value">
                                                                <template v-if="['textfield','number','number-slider','calendar'].includes(group.type)">
                                                                    {{selectedSubmission.data.questions[question.id].group[group.id].value[choice.id]}}
                                                                </template>
                                                                <template v-if="group.type=='ranking'">
                                                                    {{selectedSubmission.data.questions[question.id].group[group.id].value.findIndex((value)=>{return value==choice.value})+1}}
                                                                </template>
                                                                <span style="font-weight: bold; color: green;" v-else>
                                                                    {{choice.value == selectedSubmission.data.questions[question.id].group[group.id].value ? 'SELECTED' : ''}}
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>

                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-list-item-content>
                            </v-list>
                        </v-col>
                        <v-col style="text-align: left;">
                            Raw Data
                            <json-viewer :expand-depth=5 :value="selectedSubmission.data.questions"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.reviewSubmission=false">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import MeetingMaterials from '@/components/Admin/MeetingMaterials'
export default {
    props: {
        preMeetingAnswers: {
            type: Array,
            required: true
        },
        postMeetingAnswers: {
            type: Array,
            required: true
        }
    },
    components: {
        MeetingMaterials
    },
    created: async function(){
        await this.getUsers()
        await this.getQuestionSets()
        await this.initForm()
    },
    data: function(){
        return {
            state: {
                userData: 'idle'
            },
            ui: {
                view: 'Browser',
                reviewSubmission: false
            },
            form: null,
            temp: {
                start: {
                    date: null,
                    time: null
                },
                end: {
                    date: null,
                    time: null
                }
            },
            userData: {
                groupUsers: {},
                groups: [],
                users: []
            },
            questionSets: [],
            selectedSubmission: null,
            questions: null
        }
    },
    methods: {
        selectSubmission: async function(submission){
            let response = await this.sendRequest('get',`/api/admin/questions/${submission.questions_id}`)
            if(response.status==200){
                this.questions = response.data.questions[0]
            }
            this.selectedSubmission = submission
            this.ui.reviewSubmission = true

        },
        async getQuestionSets(){
            return new Promise(async(resolve, reject)=>{
                let response = await this.sendRequest('get',`/api/admin/question_sets`)
                this.questionSets = []
                if(response.status==200){
                    this.questionSets = response.data
                }
                resolve()
            });
        },
        async getUsers(){
            return new Promise(async(resolve, reject)=>{
                let self = this
                self.state.userData='busy'
                let response = await this.sendRequest('get',`/api/admin/users`)
                if(response.status==200){
                    let data = response.data.userData
                    for(let key in data){
                        this.userData[key] = data[key]
                    }
                }
                setTimeout(function(){
                    self.state.userData='ready'
                    resolve()
                },800)
            });
        },
        getAttribute: function(key, attributes){
            let results = attributes.filter((item)=>{return item.Name==key})[0].Value
            return results
        },
        initForm: function(){
            return new Promise(async(resolve, reject)=>{
                let form = JSON.stringify(this.model)
                this.form = JSON.parse(form)

                let avoidRoles = this.avoidRoles
                let groups = this.userData.groups
                for(let i=0; i<groups.length; i++){
                    let group = groups[i]
                    if(!avoidRoles.includes(group.GroupName) && !this.form.roles[group.GroupName]){
                        this.form.roles[group.GroupName] = []
                    }
                }

                function resolveDigits(number){
                    if(number<10){
                        return `0${number}`
                    }else{
                        return number
                    }
                }

                let start = this.model.start ? new Date(this.model.start) : null
                let end = this.model.end ? new Date(this.model.end) : null

                if(end){
                    this.temp.start.date = `${start.getFullYear()}-${resolveDigits(start.getMonth()+1)}-${resolveDigits(start.getDate())}`
                    this.temp.start.time = `${start.getHours()}:${start.getMinutes()}`
                }

                if(end){
                    this.temp.end.date = `${end.getFullYear()}-${resolveDigits(end.getMonth()+1)}-${resolveDigits(end.getDate())}`
                    this.temp.end.time = `${end.getHours()}:${end.getMinutes()}`
                }

                resolve()
            });
        },
        save: function(){
            this.$emit('save',this.form)
        },
        addResource: function(asset){
            let record = {
                label: null,
                asset,
                restrictedTo: []
            }
            this.form.resources.push(record)
        },
        assignRole: function(user, role){
            let target = this.form.roles[role]
            let userSearch = target.filter((record)=>{return record==user.Username})
            if(userSearch.length==0){
                target.push(user.Username)
            }
            this.$forceUpdate()
        },
        removeRole: function(userName, role){
            let target = this.form.roles[role]
            for(let i=0; i<target.length; i++){
                let user = target[i]
                if(user==userName){
                    target.splice(i,1)
                    break;
                }
            }
            this.$forceUpdate()
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        languages: function(){
            return this.$store.getters.languages
        },
        language: function(){
            return this.$store.getters.language
        },
        duration: function(){
            let output
            let temp = this.temp
            if((temp.start.date && temp.start.time) && (temp.end.date && temp.end.time)){
                let start = new Date(temp.start.date+' '+temp.start.time)
                let end = new Date(temp.end.date+' '+temp.end.time)
                this.form.start = start.getTime()
                this.form.end = end.getTime()
                output = (end-start) / 1000 / 60
            }

            return output
        },
        allowSave: function(){
            let form = this.form
            let language = this.language
            let tests = {
                title: form.title[language]!=null && form.title[language].length>0,
                agenda: form.agenda[language]!=null && form.agenda[language].length>0,
                start: form.start!=null,
                end: form.end!=null,
                duration: this.duration>=0
            }
            for(let key in tests){
                if(!tests[key]){
                    return false
                }
            }
            return true
        },
        userMap: function(){
            let output = {}
            let userData = this.userData
            if(userData && userData.users){
                for(let i=0; i<userData.users.length; i++){
                    let user = userData.users[i]
                    output[user.Username] = user
                }
            }
            return output
        },
        questionSetItems: function(){
            let language = this.language
            let output = []
            let questionSets = this.questionSets
            for(let i=0; i<questionSets.length; i++){
                let questionSet = questionSets[i]

                let title = '';
                if( questionSet.title != null){
                    title = questionSet.title[language];
                };

                let description = '';
                if( questionSet.description != null){
                    description = questionSet.description[language];
                };

                output.push({
                    text: `${title} - ${description}`,
                    value: questionSet.id
                })
            }

            return output
        },
        avoidRoles: function(){
            return ['SuperAdmin','admin','tester']
        },
        meetingCategoryState: function(){
            return this.form.category==='in-person' ? 'In-Person' : 'Online'
        }
    },
    watch: {
        model: {
            deep: true,
            handler: function(){
                this.initForm()
            }
        }
    }
}
</script>

<style lang="css" scoped>
::v-deep .v-messages__wrapper {
    margin-left: -33px;
    margin-top: -50px;
}

::v-deep .custom-red .v-input--selection-controls__input div {
  color: #c45d4a;
}
</style>

