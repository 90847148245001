<template>
  <div>
    <v-btn :class="[{'selected':ui.view=='surveyManagement'}]" @click="ui.view='surveyManagement'" large text>Survey Management</v-btn>
    <v-btn :class="[{'selected':ui.view=='questionEditor'}]" @click="ui.view='questionEditor'" large text>Questions Set Editor</v-btn>

    <div v-if="ui.view=='surveyManagement'">
        <v-container>
            <v-row>
                <v-col v-if="!editing.questionSet && ready==true">
                    <v-btn small text color="success" @click="newQuestionSet">New</v-btn>
                    <h3>Question Sets</h3>
                    <v-list dense style="text-align: left;">
                        <v-list-item v-for="questionSet in questionSets" :key="`setSelector-${questionSet.id}`" style="border: 1px solid lightgrey;">
                            <v-list-item-action>
                                <v-btn small icon @click="editQuestionSet(questionSet)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title v-if="questionSet.title != null">{{questionSet.title[language]}}</v-list-item-title>
                                <v-list-item-subtitle v-if="questionSet.description != null">{{questionSet.description[language]}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                Published: {{questionSet.publish}}
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col v-else>
                    <v-card>
                        <QuestionSetForm v-if="editing.questionSet" :questions="questions" :language="language" v-model="editing.questionSet"/>
                        <v-card-actions>
                            <v-btn color="warning" @click="editing.questionSet=null">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="saveQuestionSet">Save</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>

    <div v-if="ui.view=='questionEditor'">
        <v-container fluid>
            <v-row>
                <v-col v-if="!editing.questions">
                    <v-btn color="success" large text @click="newQuestions">New</v-btn>
                    <v-select v-model="temp.questions" :items="questionItems" label="Select Question Set" @change="editQuestions"/>
                </v-col>
                <v-col v-else>
                    <v-card>
                        <v-card-text>
                            <v-text-field v-model="editing.questions.description" label="Question Set Description" outlined/>
                            <LogicsEditor @update="updateLogics" :model="{questions: editing.questions.data, logics: editing.logics.data, prompts: editing.prompts.data }" :groupTypes="groupTypes" :language="language" :languages="languages"/>
                            <QuestionsEditor @update="updateQuestions" @cancel="editing.questions = null; temp.questions=null" @save="save" :model="{questions: editing.questions.data, logics: editing.logics.data, prompts: editing.prompts.data }" :groupTypes="groupTypes" :language="language" :languages="languages"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
  </div>
</template>

<script>
import QuestionSetForm from './lib/questionSetForm'
import QuestionsEditor from './lib/questionsEditor'
import LogicsEditor from './lib/logicsEditor'
export default {
    name: "QuestionSetAdmin",
    components: {
        QuestionSetForm,
        QuestionsEditor,
        LogicsEditor
    },
    created: async function(){
        await this.getQuestionSets()
        await this.getQuestions()
        this.ready = true
    },
    data: function(){
        return {
            ui: {
                view: 'surveyManagement'
            },
            questionSets: [],
            questions: [],
            editing: {
                questionSet: null,
                questions: null,
                logics: null,
                prompts:null
            },
            temp: {
                questions: null
            },
            ready: false
        }
    },
    methods: {
        getQuestionSets: async function(){
            return new Promise(async(resolve, reject)=>{
                let response = await this.sendRequest('get','/api/admin/question_sets')
                this.questionSets = []
                if(response.status==200){
                    this.questionSets = response.data
                }
                resolve()
                this.$forceUpdate()
            })
        },
        newQuestionSet: async function(){
            let languages = this.languages
            function template(){
                let output = {
                    title: {},
                    description: {},
                    dev_id: null,
                    qa_id: null,
                    prod_id: null,
                    publish: "N"
                }

                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output.title[language] = null
                    output.description[language] = null
                }

                return output
            }
            await this.getQuestions()
            this.editing.questionSet = template()
        },
        editQuestionSet: async function(questionSet){
            let temp = JSON.stringify(questionSet)
            await this.getQuestions()
            this.editing.questionSet = JSON.parse(temp)
        },
        saveQuestionSet: async function(){
            let data = this.editing.questionSet
            let response = await this.sendRequest('post','/api/admin/question_sets', data)
            if(response.status==200){
                await this.getQuestionSets()
                this.editing.questionSet = null
            }
        },
        getQuestions: async function(){
            return new Promise(async(resolve, reject)=>{
                let response = await this.sendRequest('get','/api/admin/questions')
                if(response.status==200){
                    this.questions = response.data
                }
                resolve()
            })
        },
        newQuestions: async function(){
            function template(){
                let output = {
                    version: 1,
                    description: null,
                    data: []
                }
                return output
            }

            this.editing.questions = template()
            this.editing.logics = template()
            this.editing.prompts = template()
        },
        editQuestions: async function(){
            let questions = JSON.stringify(this.temp.questions)
            questions = JSON.parse(questions)
            // this.editing.questions = questions
            let response = await this.sendRequest('get',`/api/admin/questions/${questions.id}`)
            if(response.status==200){
                this.editing.questions = response.data.questions[0]
                this.editing.logics = response.data.logics[0]
                this.editing.prompts = response.data.prompts[0]
            }
        },
        save: async function(){
            let data = {
                questions: this.editing.questions,
                logics: this.editing.logics,
                prompts: this.editing.prompts
            }
            let response = await this.sendRequest('post','/api/admin/questions', data)
            if(response.status==200){
                await this.getQuestions()
                this.temp.questions = null
                for(let key in this.editing){
                    this.editing[key] = null
                }
            }
        },
        updateQuestions: function(questions){
            this.editing.questions.data = questions
        },
        updateLogics: function(logics){
            this.editing.logics.data = logics
        }
    },
    computed: {
        languages: function(){
            return this.$store.getters.languages
        },
        language: function(){
            return this.$store.getters.language
        },
        questionItems: function(){
            let output = []
            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let set = questions[i]
                output.push({
                    text: `[${set.id}] - ${set.description}`,
                    value: set
                })
            }
            return output
        },
        groupTypes: function(){
            return ['checkbox','radio','textfield','number','number-slider','toggle','rating','ranking','calendar']
        }
    }
}
</script>

<style scoped lang="scss">
.selected{
    font-weight: bold;
    color: teal;
}
</style>
