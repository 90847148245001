<template>
  <div>
    <v-container>
      <v-row>
        <v-col v-if="ready">
          <v-btn v-if="view!='Uploader'" @click="view='Uploader'">Uploader</v-btn>
          <v-btn v-if="view!='Browser'" @click="view='Browser'">Browser</v-btn>
          <component :is="view" @selectAsset="selectAsset" @setBucket="setBucket"/>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="ui.selection" width="600">
      <v-card v-if="ui.selection">
        <v-card-subtitle>
          What would you like to do?
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-btn @click="deleteAsset" color="error">Delete</v-btn>
              </v-col>
              <v-col>
                <v-btn :href="preSignedUrl" color="success">Download</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="ui.selection = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Uploader from '@/components/Assets/Uploader'
import Browser from '@/components/Assets/Browser'
export default {
  components: {
    Uploader,
    Browser
  },
  data: function(){
    return {
      ready: true,
      view: 'Browser',
      selection: null,
      bucket: null,
      preSignedUrl: null,
      ui: {
        selection: false
      }
    }
  },
  methods: {
    setBucket: function(bucket){
      this.bucket = bucket
    },
    selectAsset: async function(asset){
      this.selection = asset
      let response = await this.sendRequest('put','/api/assets/signedUrl',asset)
      console.log(response)
      if(response.status==200){
        this.preSignedUrl = response.data.url
      }
      this.ui.selection = true
    },
    deleteAsset: async function(){
      let asset = this.selection
      if(confirm('Delete file?\r\n'+asset.Key)){
        await this.sendRequest('delete','/api/admin/assets',{selection:asset})
        this.ready = false
      }
    }
  }
}
</script>

<style>

</style>