// src/mixins/allowTopic.js
export default {
    methods: {
        allowTopic: function(topic, meetingMap){
            let username = this.$store.getters.user.username
            let restrictedTo = topic.restrictedTo
            let events = topic.events

            let myRoles = []
            for(let i=0; i<events.length; i++){
                let event_id = events[i]
                let meeting = meetingMap[event_id]

                if(meeting){
                    for(let role in meeting.roles){
                        let users = meeting.roles[role]
                        if(users.find((value)=>{return value==username})){
                            myRoles.push(role)
                            if(!this.allMyRoles.includes(role)){
                                this.allMyRoles.push(role)
                            }
                        }
                    }
                }
            }

            let allowRole = false
            //Matching role to meeting related assignemnt
            for(let i=0; i<myRoles.length; i++){
                let myRole = myRoles[i]
                if(restrictedTo.includes(myRole)){
                    allowRole = true
                }
            }

            //Matching role to any meeting role assignment
            if(events.length==0 && restrictedTo.length>0){
                for(let i=0; i<restrictedTo.length; i++){
                    let role = restrictedTo[i]
                    if(this.allMyRoles.includes(role)){
                        allowRole = true
                    }
                }
            }

            let result = restrictedTo.length==0 || allowRole || this.$route.name=='Admin'
            return result
        }
    }
};