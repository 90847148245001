<template>
  <div>
    <v-btn @click="tooglePreview">Preview</v-btn>
    <v-container fluid v-if="!ui.preview">
        <v-row>
            <v-col v-if="!editing.question">
                <v-card flat>
                    <v-card-text>
                        <v-btn text color="success" @click="newQuestion">
                            <v-icon>mdi-plus</v-icon>Question
                        </v-btn>
                        <v-list dense style="text-align: left;">
                            <draggable v-model="form.questions">
                                <v-list-item v-for="(question, index) in form.questions" :key="`question-selector-${index}`" style="border: 1px solid lightgrey;">
                                    <v-list-item-action>
                                        {{index+1}}.
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <template v-if="question.header.text[language]?.length>0">
                                                {{question.header.text[language]}} - 
                                            </template>
                                            {{question.body.text[language]}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{question.subtext.text[language]}}
                                        </v-list-item-subtitle>                                        
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <div>
                                            <v-btn icon small @click="editQuestion(question)">
                                                <v-icon>
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>

                                            <v-btn icon small color="error" @click="deleteQuestion(question)">
                                                <v-icon>
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </draggable>
                        </v-list>                        
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-btn color="warning" @click="$emit('cancel')">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="$emit('save')">Save</v-btn>
                    </v-card-actions>                    
                </v-card>

            </v-col>

            <v-col v-else>
                <QuestionForm v-model="editing.question" @cancel="editing.question = null" @save="saveQuestion" :groupTypes="groupTypes" :logics="model.logics" :language="language" :languages="languages"/>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row>
            <v-col>
                <Preview :questionSetID="0" v-model="preview.questionSet" :requestDebug="true"/>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import Preview from '../../QuestionSet.vue'
import draggable from 'vuedraggable'
import QuestionForm from './questionForm.vue'
export default {
    props: {
        groupTypes: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    components: {
        draggable,
        QuestionForm,
        Preview
    },
    created: function(){
        let data = JSON.stringify(this.model)
        this.form = JSON.parse(data)
    },
    data: function(){
        return {
            ui: {
                preview: false
            },
            form: {},
            editing: {
                question: null
            },
            preview: null
        }
    },
    methods: {
        getID: function(array){
            let ids = []
            for(let i=0; i<array.length; i++){
                ids.push(array[i].id)
            }

            let id=1
            for(let i=0; i<ids.length; i++){
                if(!ids.includes(id)){
                    return id
                }
                id++
            }
            return id
        },
        newQuestion: function(){
            let languages = this.languages
            function template(id){
                let output = {
                    id,
                    display: true,
                    displayLogic: null,
                    canHide: false,
                    header: {text:{}},
                    subtext: {text:{}},
                    body: {text:{}},
                    groups: [],
                    footer: {text:{}},
                }

                for(let i=0; i<languages.length; i++){
                    let language=languages[i]
                    output.header.text[language] = null
                    output.subtext.text[language] = null
                    output.body.text[language] = null
                    output.footer.text[language] = null
                }

                return output
            }
            let id = this.getID(this.form.questions)
            let question = template(id)
            this.editing.question = question
        },
        editQuestion: function(question){
            let data = JSON.stringify(question)
            this.editing.question = JSON.parse(data)
        },
        saveQuestion: function(){
            let data = JSON.stringify(this.editing.question)
            data = JSON.parse(data)

            let index = this.form.questions.findIndex((record)=>{return record.id==data.id});
            if(index<0){
                this.form.questions.push(data)
            }else{
                this.form.questions.splice(index,1,data)
            }
            this.editing.question = null
        },
        deleteQuestion: function(question){
            if(confirm('Delete Question?')){
                let index = this.form.questions.findIndex((record)=>{return record.id==question.id});
                this.form.questions.splice(index,1)
            }
        },
        tooglePreview: function(){
            let model = JSON.stringify(this.model)
            model = JSON.parse(model)
            this.preview = {
                questionSet: {
                    hub: {
                        logicIndex: [],
                        logs: []
                    },
                    logics: model.logics,
                    prompts: model.prompts,
                    questions: model.questions
                },
                ready: true,
                questionSetVersion: 0,
                questionSetID: 0
            }
            this.ui.preview = !this.ui.preview
        }
    },
    computed: {
        questions: function(){
            return this.form.questions
        }
    },
    watch: {
        questions: {
            deep: true,
            handler: function(){
                let questions = this.questions
                console.log('questionEditor: update',questions)
                this.$emit('update',questions)
            }
        }
    }
}
</script>

<style>

</style>