<template>
  <div>
    <!-- <h3 v-if="LabelKey">{{LabelKey}}</h3> -->

    <v-expansion-panels>
        <v-expansion-panel v-for="(label, key) in labels" :Label="label" :LabelKey="key" :key="`label-item-${key}`" style="margin-left: 5px;">
          <template v-if="key!='_text'">
            <v-expansion-panel-header>
              {{key}}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field label="New Label:  No spaces / Use '.' to separate levels"  v-model="newKey" :append-icon="newKey && newKey.length>0 ? 'mdi-new-box' : ''" @click:append="$emit('newLabel',LabelKey ? `${LabelKey}.${key}.${newKey}` : `${key}.${newKey}`)" clearable/>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn v-if="label._text" icon color="error" @click="$emit('deleteLabel',LabelKey ? `${LabelKey}.${key}` : key)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <template>
                <LabelSelector :labels="label" :LabelKey="LabelKey ? `${LabelKey}.${key}` : key" @editLabel="editLabel" @newLabel="newLabel" @deleteLabel="deleteLabel"/>
              </template>
              <v-container v-if="label._text" fluid>
                <v-row>
                  <v-col v-for="language in languages" :key="`${key}-${language}`">
                    <h4>{{language}}</h4>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{LabelKey}}.{{key}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{label._text[language]}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="editLabel(LabelKey ? `${LabelKey}.${key}` : key)">
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>

            </v-expansion-panel-content>
          </template>



        </v-expansion-panel>  
    </v-expansion-panels>

  </div>
</template>

<script>
import LabelItem from './labelItem'
export default {
  name: "LabelSelector",
  props: {
    labels: {
      type: Object,
      required: true
    },
    LabelKey: {
      type: String,
      required: false
    }
  },
  components: {
    LabelItem
  },
  data: function(){
    return {
      newKey: null
    }
  },
  methods: {
    editLabel: function(key){
      this.$emit('editLabel',key)
    },
    newLabel: function(key){
      this.$emit('newLabel',key)
    },
    deleteLabel: function(key){
      this.$emit('deleteLabel',key)
    }
  },
  computed: {
    languages: function(){
      return this.$store.getters.languages
    }
  }
}
</script>

<style>

</style>