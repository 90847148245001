<template>
  <div>
    <v-tabs v-model="activeTab" background-color="transparent">
        <v-tab v-for="tab in tabs" :key="`${tab.key}`">
            {{tab.label}}
        </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab" style="background-color: transparent;">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <component v-if="activeTab==index" :is="tab.component" style="margin: 25px;"/>
        </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EventsAdmin from '@/components/Event/Admin'
import DiscussionForumAdmin from '@/components/DiscussionForum/Admin'
import ResourcesAdmin from '@/components/Resources/Admin'
import Users from '@/components/Admin/Users'
import QuestionSetEditor from '@/components/Questions/Editor/Editor'
import LabelEditor from '@/components/Globals/LabelEditor'

export default {
    created: function(){
        if(this.$route.name=='LabelAdmin' || this.$route.name=='LabelAdminEditor'){
            this.activeTab = this.tabs.findIndex((record)=>{return record.key=='Labels'})
        }
    },
    data: function(){
        return {
            activeTab: 0
        }
    },
    computed: {
        tabs: function(){
            return [
                {
                    key: 'Events',
                    label: 'Meetings',
                    component: EventsAdmin
                },
                {
                    key: 'Surveys',
                    label: 'Surveys',
                    component: QuestionSetEditor
                },
                {
                    key: 'DiscussionForum',
                    label: 'Discussion Forum',
                    component: DiscussionForumAdmin
                },
                {
                    key: 'Resources',
                    label: 'Resources',
                    component: ResourcesAdmin
                },
                {
                    key: 'Users',
                    label: 'Users',
                    component: Users
                },
                {
                    key: 'Labels',
                    label: 'Labels',
                    component: LabelEditor
                }
            ]
        }
    }
}
</script>

<style>

</style>