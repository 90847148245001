<template>
  <div>
    <v-container fluid>
        <v-row>
            <v-col v-if="!editMeeting">
                <div v-for="meeting in meetings" :key="`meeting-selector-${meeting.id}`" style="width: 400px;position: relative;">
                    <EventCard :meeting="meeting" style="max-width: 400px; margin-bottom: 3px;" :class="[{'unpublished':meeting.publish!='Y'}]"/>
                    <v-btn @click="selectMeeting(meeting)" small icon style="position: absolute; top: 10px; right: 10px;">
                        {{meeting.id}}
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col v-else>
                <EventForm v-model="editMeeting" @save="saveMeeting" @cancel="editMeeting=null" :preMeetingAnswers="preMeetingAnswers" :postMeetingAnswers="postMeetingAnswers"/>
            </v-col>
        </v-row>
    </v-container>
    <v-btn v-if="!editMeeting" @click="newMeeting" fab color="green" dark style="position: fixed; right: 25px; bottom: 60px;">
        <v-icon>
            mdi-plus
        </v-icon>
    </v-btn>

    <v-dialog v-model="showBusy" width="100">
        <v-card>
            <v-card-text><br>
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>                
            </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventCard from '@/components/Event/EventCard'
import EventForm from '@/components/Event/Admin/EventForm'
export default {
    name: "EventAdmin",
    components: {
        EventCard,
        EventForm
    },
    created: function(){
        this.getMeetings()
        this.getAnswers()
    },
    data: function(){
        return {
            busy: 0,
            meetings: [],
            editMeeting: null,
            preMeetingAnswers: null,
            postMeetingAnswers: null
        }
    },
    methods: {
        async getAnswers(){
            this.busy++
            let response = await this.sendRequest('get',`/api/admin/premeeting`)
            if(response.status==200){
                this.preMeetingAnswers = response.data
            }

            response = await this.sendRequest('get',`/api/admin/postmeeting`)
            if(response.status==200){
                this.postMeetingAnswers = response.data
            }
            this.busy--
        },
        getMeetings: async function(){
            this.busy++
            let response = await this.sendRequest('get','/api/admin/meetings')
            if(response.status==200){
                let meetings = response.data
                this.meetings = meetings
            }
            this.busy--
        },
        selectMeeting: function(event){
            this.editMeeting = event
        },
        saveMeeting: async function(event){
            this.busy++
            this.editMeeting = event
            let response = await this.sendRequest('post','/api/admin/meetings/save', event)
            if(response.status==200){
                this.editMeeting = null
                await this.getMeetings()
                await this.propagateMeetings()
            }
            this.busy--
        },
        newMeeting: function(){
            let record = {
                publish: "Y",
                category: "online",
                title: {},
                agenda: {},
                preMeetingSurvey: null,
                start: null,
                end: null,
                roles: {},
                resources: [],
                meetinID: null,
                meetingLink: null,
                meetingPassword: null
            }

            let languages = this.$store.getters.languages
            for(let i=0; i<languages.length; i++){
                record.title[languages[i]] = null
                record.agenda[languages[i]] = null
            }
            console.log('newMeeting', record)
            this.editMeeting = record
        },
        propagateMeetings: async function(){
            this.busy++
            await this.sendRequest('put','/api/admin/meetings/propagate')
            this.busy--
        }
    },
    computed: {
        showBusy: function(){
            return this.busy>0
        },
        // allowAddRole: function(){
        //     let role = this.role
        //     let index = this.roles.findIndex((record)=>{return record.id==role.id})
        //     let tests = {
        //         description: (role.description && role.description.length>0),
        //         id: (role.id && role.id.length>0),
        //         notExist: index<0
        //     }
        //     for(let test in tests){
        //         if(!tests[test]){
        //             return false
        //         }
        //     }
        //     return true
        // }
    }
}
</script>

<style scoped lang="scss">
.unpublished{
    background-color: lightgrey;
}
</style>