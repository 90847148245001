<template>
  <div style="text-align: left;">
    <v-text-field v-model="searchString" prepend-icon="mdi-magnify" label="Search Topics" clearable/>
    <v-list class='topicList'>
        <template v-for="(topic, index) in filteredTopics.slice().reverse()">
            <v-list-item v-if="allowTopic(topic, meetingMap)" class='topicItem' :class="[{'selected':selectedTopic && selectedTopic.id==topic.id}]" :key="`topic-${index}`" @click="$emit('selectTopic',topic)">

                <v-list-item-action class='topicIcon'>
                    <v-list-item-icon class="ma-0" v-if="allowAccess(['SuperAdmin','admin','staff','tester'])" @click="copyToClipboard($event, topic.id)">
                        <v-icon dense small title='Copy Topic URL to Clipboard'>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                </v-list-item-action>

                <v-list-item-content class='topicContent'>

                    <v-list-item-title class='topicTitle'>
                        {{topic.title[language]}}
                    </v-list-item-title>

                    <v-list-item-subtitle class='topicSubtitle'>
                        {{topic.subtitle[language]}}
                    </v-list-item-subtitle>

                    <v-chip-group>
                        <v-chip v-for="tag in topic.tags[language]" :key="tag" x-small>{{tag}}</v-chip>
                    </v-chip-group>
                </v-list-item-content>

            </v-list-item>
        </template>
    </v-list>

    <v-snackbar v-model="state.snackbar.display" rounded="pill" left centered>
        <span v-html="state.snackbar.message"/>
    </v-snackbar>
  </div>
</template>

<script>
import allowTopic from '@/mixins/allowTopic.js'
import allowAccess from '@/mixins/allowAccess.js'
export default {
    props: {
        selectedTopic: {
            type: Object,
            required: false
        },
        topics: {
            type: Array,
            required: true
        }
    },
    mixins: [allowTopic,allowAccess],
    data: function(){
        return {
            searchString: null,
            allMyRoles: [],
            state: {
                snackbar: {
                    display: false,
                    message: null
                }
            },
            ready: false
        }
    },
    created: function(){
        if( this.ready == false){
            this.ready = true;
            let delay = setTimeout(function(){
                let topicContentContainers = document.querySelectorAll(`.topicContent`);
                for(let i = 0; i < topicContentContainers.length; i++){
                    let item = topicContentContainers[i];
                    item.addEventListener('wheel', function(event){
                        event.preventDefault();
                        item.scrollLeft += event.deltaY;
                    });
                };
            },2000);
        };
    },
    methods: {
        copyToClipboard: function(event, topic_id){
            event.stopImmediatePropagation();
            let self = this;
            navigator.clipboard.writeText("https://"+window.location.hostname + "/discussionforum/"+topic_id)
            .then(() => {
                    //alert('Topic URL copied to clipboard');
                    self.state.snackbar.display = true;
                    self.state.snackbar.message = "Topic URL copied to clipboard";
            })
            .catch(err => {
                // This can happen if the user denies clipboard permissions:
                console.error('Could not copy text: ', err);
            });
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        filteredTopics: function(){
            let topics = this.topics
            let searchString = this.searchString && this.searchString.length>0 ? this.searchString.toLowerCase() : ''
            let language = this.language
            if(searchString && searchString.length>0){
                let output = topics.filter((topic)=>{
                    let title = topic.title[language] ? topic.title[language] : ''
                    let subtitle = topic.subtitle[language] ? topic.subtitle[language] : ''
                    return (title.toLowerCase().indexOf(searchString)>=0 || subtitle.toLowerCase().indexOf(searchString)>=0 || topic.tags[language].includes(searchString))
                })
                return output
            }else{
                return topics
            }
        },
        meetingMap: function(){
            let output = {}
            let meetings = this.$store.getters.meetings
            if(meetings){
                for(let i=0; i<meetings.length; i++){
                    output[meetings[i].id] = meetings[i]
                }
            }
            return output
        }
    }
}
</script>

<style scoped lang="scss">
    .selected{
        background-color: whitesmoke;
        color: red;
    }

    .topicIcon {
        margin-right: 16px !important;
    }

    .topicList {
        overflow-x: hidden;
    }

    .topicItem {
        overflow-x: hidden;
    }

    .topicContent {
        display: block;
        flex: initial;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .topicTitle,
    .topicSubtitle {
        overflow: visible;
        text-overflow: inherit;
    }
</style>
