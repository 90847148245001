<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-switch true-value="Y" false-value="N" v-model="model.publish" label="Publish"/>
          <v-text-field label="Title" v-model="model.title[language]"/>
          <v-textarea label="Description" v-model="model.description[language]"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select v-model="model.dev_id" label="Dev Questions Set" clearable :items="questionItems"/>
        </v-col>
        <v-col>
          <v-select v-model="model.qa_id" label="QA Question Set" clearable :items="questionItems"/>
        </v-col>
        <v-col>
          <v-select v-model="model.prod_id" label="Production Question Set" clearable :items="questionItems"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "QuestionSetForm",
  props: {
    language: {
      type: String,
      required: false,
      default: 'en-ca'
    },
    questions: {
      type: Array,
      required: true
    }
  },
  computed: {
    model: function(){
      return this.$attrs.value
    },
    questionItems: function(){
      let questions = this.questions
      let output = []

      for(let i=0; i<questions.length; i++){
        let record = questions[i]
        output.push({
          text: `[${record.id}] - ${record.description}`,
          value: record.id
        })
      }

      return output
    }
  }
}
</script>

<style>

</style>