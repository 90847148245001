<template>
  <div>
    <v-container>
        <v-row>
            <v-col style="text-align: left;">
                <v-switch :true-value="true" :false-value="false" v-model="group.display" label="Display Group"/>
                <v-switch :true-value="true" :false-value="false" v-model="group.disabled" label="Disable Group"/>
                <v-switch :true-value="true" :false-value="false" v-model="group.requirements.required" label="Required"/>
                <template v-if="group.requirements.required">
                    <v-text-field type="number" v-model="group.requirements.min" clearable label="Minimum"/>
                    <v-text-field type="number" v-model="group.requirements.max" clearable label="Maximum"/>
                </template>
                <v-select v-model="group.type" label="Question Type" :items="groupTypes" outlined/>
                <draggable v-model="group.choices">
                    <v-card v-for="(choice, index) in group.choices" :key="`choice-card-${choice.id}`">
                        <v-card-subtitle>
                            {{index+1}}.  [{{choice.id}}]
                        </v-card-subtitle>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="choice.text[language]" label="Choice Label"/>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="choice.value" label="Value"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-switch :true-value="true" :false-value="false" v-model="choice.other" label="Other"/>
                            <v-text-field v-if="choice.other" v-model="choice.other_text[language]" label="Other Text"/>
                            <v-switch :true-value="true" :false-value="false" v-model="choice.exclusive" label="Exclusive"/>
                        
                        
                        </v-card-text>
                        <v-card-actions>
                            <v-btn small icon color="error" @click="deleteChoice(index)">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </draggable>
                <hr>
                <v-btn small text @click="newChoice">+Choice</v-btn>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    name: "GroupForm",
    props: {
        groupTypes: {
            type: Array,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        draggable
    },
    created: function(){
        let group = JSON.stringify(this.model)
        this.group = JSON.parse(group)
        if(this.group.choices.length==0){
            this.newChoice()
        }
    },
    data: function(){
        return {
            group: {}
        }
    },
    methods: {
        getID: function(array){
            let ids = []
            for(let i=0; i<array.length; i++){
                ids.push(array[i].id)
            }

            let id=1
            for(let i=0; i<ids.length; i++){
                if(!ids.includes(id)){
                    return id
                }
                id++
            }
            return id
        },
        newChoice: function(){
            let languages = this.languages
            function template(id){
                let output = {
                    id,
                    text: {},
                    value: id,
                    display: true,
                    disabled: false,
                    exclusive: false,
                    other: false,
                    other_text: {},
                    displayLogic: null,
                    disableLogic: null,
                    requirements: {
                        min: null,
                        max: null,
                        required: false
                    }
                }

                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output.text[language] = null
                    output.other_text[language] = language=='fr-ca' ? 'Veuillez préciser' : 'Please specify'
                }
                console.log('newChoice',output)
                
                return output
            }

            let id = this.getID(this.group.choices)
            let choice = template(id)
            this.group.choices.push(choice)
        },
        deleteChoice: function(index){
            if(confirm('Delete choice?')){
                this.group.choices.splice(index,1)
            }
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    },
    watch: {
        group: {
            deep: true,
            handler: function(){
                this.$emit('input',this.group)
            }
        }
    }
}
</script>

<style>

</style>