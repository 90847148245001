import { render, staticRenderFns } from "./questionGroupEditor.vue?vue&type=template&id=eff7a310&scoped=true&"
import script from "./questionGroupEditor.vue?vue&type=script&lang=js&"
export * from "./questionGroupEditor.vue?vue&type=script&lang=js&"
import style0 from "./questionGroupEditor.vue?vue&type=style&index=0&id=eff7a310&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eff7a310",
  null
  
)

export default component.exports