<template>
  <div>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card flat>
                    <v-card-text>
                        <v-switch v-model="model.display" :true-value="true" :false-value="false" label="Displayed"/>
                        <v-btn text small @click="addDisplayLogic">Add Display Logics</v-btn>
                        <v-container v-if="model.displayLogic">
                            <v-row>
                                <v-col>
                                    <v-select v-model="model.displayLogic.requirement" clearable :items="requirementItems" label="Logic Requirements" outlined/>
                                </v-col>
                                <v-col>
                                    Available Logics
                                    <v-list dense>
                                        <v-list-item v-for="logic in logics" :key="`logic-selector-${logic.id}`">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{logic.description}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn text @click="useLogic(logic)">
                                                    Use Logic
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col>
                                    Display Logics
                                    <v-list dense>
                                        <v-list-item v-for="(logic,index) in model.displayLogic.rules" :key="`logic-deselector-${logic.id}`">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{resolveLogic(logic.rule_id).description}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn text color="error" @click="removeLogic(index)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-text-field v-model="model.header.text[language]" label="Header"/>
                        <v-text-field v-model="model.body.text[language]" label="Body"/>
                        <v-text-field v-model="model.subtext.text[language]" label="Subtext"/>

                        <GroupEditor v-model="model.groups" :groupTypes="groupTypes" :language="language" :languages="languages"/>
                        
                        <v-text-field v-model="model.footer.text[language]" label="Footer"/>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn text @click="$emit('cancel')">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text @click="$emit('save')">
                            Ok
                        </v-btn>
                    </v-card-actions>
                </v-card>
       
            </v-col>
        </v-row>
    </v-container>

  </div>
</template>

<script>
import GroupEditor from './questionGroupEditor.vue'
export default {
    props: {
        logics: {
            type: Array,
            required: false,
            default: []
        },
        groupTypes: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    methods: {
        addDisplayLogic: function(){
            let logic = this.model.displayLogic
            this.model.canHide = true
            if(!logic){
                this.model.displayLogic = {
                    requirement: 'allTrue',
                    rules: []
                }
            }
            this.$forceUpdate()
        },
        useLogic: function(logic){
            this.model.displayLogic.rules.push({rule_id:logic.id})
        },
        removeLogic: function(index){
            if(confirm('Remove logic?')){
                this.model.displayLogic.rules.splice(index,1)
            }
        },
        resolveLogic: function(logicID){
            let index = this.logics.findIndex((item)=>{return item.id==logicID})
            return this.logics[index]
        }
    },
    components: {
        GroupEditor
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        requirementItems: function(){
            return [{text:'Any',value:'anyTrue'},{text:'All',value:'allTrue'},{text:'None',value:'noneTrue'},{text:'x#',value:'xTrue'}]
        }
    }
}
</script>

<style>

</style>