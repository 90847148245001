<template>
  <div>
    <v-tabs v-model="ui.activeTab">
        <v-tab>
            User Admin
        </v-tab>
        <v-tab>
            Invitees
        </v-tab>
    </v-tabs>

    <v-tabs-items v-model="ui.activeTab">
        <v-tab-item>
            <v-container fluid>
                <v-row style="text-align: left;">
                    <template v-for="group in groups">
                        <v-col v-if="allowedGroups.includes(group.GroupName)" :key="`group-${group.GroupName}`">
                            {{group.GroupName}}
                            <v-list>
                                <v-list-item v-for="user in groupUsers[group.GroupName]" :key="`user-${user.Username}`">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{fullName(user.Attributes)}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{email(user.Attributes)}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn @click="removeFromGroup(user.Username, group.GroupName)" icon small color="error">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </template>
                </v-row>
            </v-container>

            <v-list style="text-align: left; max-width: 1024px;" dense>
                <template v-for="user in users">
                    <v-list-item :key="`user_${user.Username}`" class="hoverable">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{fullName(user.Attributes)}}
                                <template v-if="user.UserStatus=='EXTERNAL_PROVIDER'">
                                    - ({{resolveProvider(user.Username)}})
                                </template>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{email(user.Attributes)}} - {{user.UserStatus}}</v-list-item-subtitle>
                            <!-- {{new Date(user.UserCreateDate)}} -->
                        </v-list-item-content>
                        <v-list-item-action>
                            <div>
                                <template v-for="group in groups">
                                    <v-btn x-small v-if="allowedGroups.includes(group.GroupName)" @click="addToGroup(user.Username, group.GroupName)" :key="`make-group-${group.GroupName}`" style="margin: 5px;">
                                        {{group.GroupName}}
                                    </v-btn>
                                </template>
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>


            <v-container v-if="glowworm">
                <v-row>
                    <v-col>Admin</v-col>
                    <v-col>Users</v-col>
                </v-row>
                <v-row style="text-align: left;">
                    <v-col>
                        <v-list dense>
                            <template v-for="user in groupUsers.admin">
                                <v-list-item :key="`admin_${user.Username}`">
                                    <v-list-item-action>
                                        <v-btn text v-if="isAdmin(user.Username)" @click="makeUser(user)">Make User</v-btn>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{fullName(user.Attributes)}}
                                            <template v-if="user.UserStatus=='EXTERNAL_PROVIDER'">
                                                - ({{resolveProvider(user.Username)}})
                                            </template>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{email(user.Attributes)}} - {{user.UserStatus}}</v-list-item-subtitle>
                                        <!-- {{new Date(user.UserCreateDate)}} -->
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                    <v-col>
                        <v-list dense>
                            <template v-for="user in users">
                                <v-list-item :key="`user_${user.Username}`">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{fullName(user.Attributes)}}
                                            <template v-if="user.UserStatus=='EXTERNAL_PROVIDER'">
                                                - ({{resolveProvider(user.Username)}})
                                            </template>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{email(user.Attributes)}} - {{user.UserStatus}}</v-list-item-subtitle>
                                        <!-- {{new Date(user.UserCreateDate)}} -->
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn text v-if="!isAdmin(user.Username)" @click="makeAdmin(user)">Make Admin</v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>

        </v-tab-item>

        <v-tab-item>
            <v-btn @click="newInvitee">New Invitee</v-btn>
            <v-container>
                <v-row>
                    <v-col>
                        <h3>Invitees</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col/>
                    <v-col>
                        Name
                    </v-col>
                    <v-col>
                        Email
                    </v-col>
                    <v-col>
                        Sign Up Path
                    </v-col>
                    <v-col/>
                </v-row>
                <v-row v-for="(record, index) in invitees" :key="`invitee-${index}`" style="text-align: left;">
                    <v-col>
                        <template v-if="!userEmails.find((email)=>{return email==record.email})">
                            <v-btn icon @click="editInvitee(record)">
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-col>
                    <v-col>
                        {{record.invitee.given_name}} {{record.invitee.family_name}}
                    </v-col>
                    <v-col>
                        {{record.email}}
                    </v-col>
                    <v-col>
                        {{$router.getRoutes().find((e)=>{return e.name=='SignUp'}).path.split(':')[0]}}{{record.hash}}
                    </v-col>
                    <v-col>
                        <v-switch style="position: relative; top: -20px;" v-model="record.enabled" true-value="Y" false-value="N" label="Enabled Invitation" @change="toggleInvite(record)"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-dialog v-model="ui.displayInviteeForm">
                <v-card v-if="ui.displayInviteeForm">
                    <v-card-subtitle>
                        Invitee Form
                    </v-card-subtitle>
                    <v-card-text>
                        <v-text-field label="Email" v-model="editing.email"/>
                        <v-text-field label="First Name" v-model="editing.invitee.given_name"/>
                        <v-text-field label="Last Name" v-model="editing.invitee.family_name"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text @click="closeEditor">
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text @click="saveInvitee">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="busy" width="100" persistent>
        <v-card>
            <v-card-text>
                <br>
                <v-icon>
                    mdi-spin mdi-loading
                </v-icon>
            </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: "UserAdmin",
    created: function(){
        this.getUsers()
    },
    data: function(){
        return {
            queue: 0,
            glowworm: false,
            ui: {
                activeTab: 0,
                displayInviteeForm: false
            },
            states: {
                ready: false
            },
            users: [],
            groups: [],
            groupUsers: [],
            invitees: [],
            editing: null
        }
    },
    methods: {
        async getUsers(){
            this.queue++
            let response = await this.sendRequest('get',`/api/admin/users`)
            if(response.status==200){
                let userData = response.data.userData
                let invitees = response.data.invitees
                this.invitees = invitees
                for(let key in userData){
                    this[key] = userData[key]
                }
            }
            this.queue--
        },
        fullName: function(attributes){
            let given_name = attributes.filter((item)=>{return item.Name=='given_name'})
            let family_name = attributes.filter((item)=>{return item.Name=='family_name'})
            return `${given_name[0].Value} ${family_name[0].Value}`
        },
        email: function(attributes){
            let email = attributes.filter((item)=>{return item.Name=='email'})
            return email[0].Value
        },
        resolveProvider: function(username){
            let isGoogle = username.indexOf('google_') == 0
            let isFacebook = username.indexOf('facebook_') == 0
            if(isGoogle){ return 'Google'}
            if(isFacebook){ return 'Facebook'}
        },
        isAdmin: function(username){
            let admins = this.groupUsers.admin
            return admins.filter((user)=>{return user.Username == username}).length>0
        },
        async addToGroup(Username, GroupName){
            this.queue++
            let response = await this.sendRequest('post','/api/admin/users/addToGroup',{Username, GroupName})
            await this.getUsers()
            this.queue--
        },
        async removeFromGroup(Username, GroupName){
            this.queue++
            let response = await this.sendRequest('post','/api/admin/users/removeFromGroup',{Username, GroupName})
            await this.getUsers()
            this.queue--
        },
        async makeAdmin(user){
            let response = await this.sendRequest('post',`/api/admin/users/makeAdmin`, user)
            console.log('makeAdmin', {user, response})
            this.getUsers()
        },
        async makeUser(user){
            let response = await this.sendRequest('post',`/api/admin/users/makeUser`, user)
            console.log('makeUser', {user, response})
            this.getUsers()
        },
        closeEditor: function(){
            this.editing = null
            this.ui.displayInviteeForm = false
        },
        saveInvitee: async function(){
            let record = this.editing
            let response = await this.sendRequest('post','/api/admin/check_in', record)
            if(response.status==200){
                await this.getUsers()
                this.closeEditor()
            }
        },
        newInvitee(){
            function template(){
                return {
                    enabled: "Y",
                    invitee: {
                        given_name: null,
                        family_name: null,
                    },
                    email: null
                }
            }
            this.editing = template()
            this.ui.displayInviteeForm = true
        },
        editInvitee(record){
            let data = JSON.stringify(record)
            this.editing = JSON.parse(data)
            this.ui.displayInviteeForm = true
        },
        async toggleInvite(record){
            let response = await this.sendRequest('post','/api/admin/check_in', record)
        }
    },
    computed: {
        allowedGroups: function(){
            return ['admin','tester','client']
        },
        userGroups: function(){
            return [
                {
                    key: 'admin',
                    label: 'Administrators'
                },
                {
                    key: 'staff',
                    label: "Internal Staff"
                },
                {
                    key: 'tester',
                    label: "QA Testers"
                }
            ]
        },
        userEmails: function(){
            let output = []
            let users = this.users
            for(let i=0; i<users.length; i++){
                let user = users[i]
                let attributes = user.Attributes
                let emailIndex = attributes.findIndex((item)=>{return item.Name=='email'})
                output.push(attributes[emailIndex].Value)

            }

            return output
        },
        busy: function(){
            return this.queue>0
        }
    },
    watch: {
        queue: function(){
            if(this.queue<0){
                this.queue=0
            }
        }
    }
}
</script>

<style scoped lang="scss">
.hoverable{
    border-bottom: solid;
}
.hoverable:hover{
    background-color: lightgrey;
    font-weight: bold;
}
</style>
