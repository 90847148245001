<template>
  <div>
    <v-container>
        <v-row>
            <v-col v-if="!editing.logic">
                <v-btn @click="newLogic" text color="success">
                    <v-icon>mdi-plus</v-icon> Logic
                </v-btn>
                <v-list dense>
                    <draggable v-model="form.logics">
                        <v-list-item v-for="(logic, index) in form.logics" :key="`logic-selector-${logic.id}`">
                            <v-list-item-content>
                                {{index+1}}. - [{{logic.id}}] | {{logic.description}}
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>
                                    <v-btn @click="editLogic(logic)" small icon color="success">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn @click="deleteLogic(logic)" small icon color="error">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-col>
            <v-col v-else>
                <v-card flat>
                    <v-card-text>
                        <v-text-field v-model="editing.logic.description" label="Description"/>
                        <v-select v-model="editing.logic.requirement" :items="requirementItems" label="How many conditions for rule to be true"></v-select>
                        <v-btn text @click="newRule">
                            <v-icon>mdi-plus</v-icon>Condition
                        </v-btn>
                        <draggable v-model="editing.logic.rules">
                            <v-card v-for="(rule, index) in editing.logic.rules" :key="`rule-card-${index}`" style="margin: 5px;">
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-select v-model="rule.source.input.question_id" :items="questionItems" @change="rule.source.input.group_id=null; rule.source.input.choice_id=null;"/>
                                                <v-select v-if="rule.source.input.question_id" v-model="rule.source.input.group_id" :items="groupItems(rule.source.input)"  @change="rule.source.input.choice_id=null;"/>
                                                <v-select v-if="rule.source.input.group_id && unknowables.includes(resolveType(rule.source.input))" v-model="rule.source.input.choice_id" :items="choiceItems(rule.source.input)"/>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-select v-model="rule.operator" :items="operators" label="Operator"></v-select>
                                            </v-col>
                                            <v-col>
                                                <template v-if="unknowables.includes(resolveType(rule.source.input))">
                                                    <v-textarea v-if="rule.source.input.choice_id" v-model="temp.stringArray[index]" label="One answer per line" @keyup="setValue(rule,index)"/>
                                                </template>
                                                <template v-else-if="rule.source.input.group_id">
                                                    <v-checkbox dense v-for="choice in resolveChoices(rule.source.input)" :key="`rule-${index}-choice-${choice.id}`" :value="choice.value" :label="choice.text[language]" v-model="rule.value"/>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn icon color="error" @click="deleteRule(index)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </draggable>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="editing.logic=null">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveLogic">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    props: {
        groupTypes: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    components: {
        draggable
    },
    created: function(){
        let data = JSON.stringify(this.model)
        this.form = JSON.parse(data)

    },
    data: function(){
        return {
            form: {},
            editing: {
                logic: null
            },
            temp: {
                stringArray: []
            }
        }
    },
    methods: {
        getID: function(array){
            let ids = []
            for(let i=0; i<array.length; i++){
                ids.push(array[i].id)
            }

            let id=1
            for(let i=0; i<ids.length; i++){
                if(!ids.includes(id)){
                    return id
                }
                id++
            }
            return id
        },
        newLogic: function(){
            function template(id){
                let output = {
                    id,
                    rules: [],
                    description: null,
                    requirement: "allTrue"
                }

                return output
            }
            let id = this.getID(this.form.logics)
            let logic = template(id)
            this.editing.logic = logic
        },
        editLogic: function(logic){
            let data = JSON.stringify(logic)
            this.editing.logic = JSON.parse(data)
            this.temp.stringArray = []
            let rules = this.editing.logic.rules
            for(let r=0; r<rules.length; r++){
                let rule = rules[r]
                let string = ''
                for(let v=0; v<rule.value.length; v++){
                    let value=rule.value[v]
                    if(v>0){
                        string+='\n'
                    }
                    string+=value
                }
                this.temp.stringArray[r] = string
            }
        },
        saveLogic: function(){
            let data = JSON.stringify(this.editing.logic)
            data = JSON.parse(data)

            let index = this.form.logics.findIndex((record)=>{return record.id==data.id});
            if(index<0){
                this.form.logics.push(data)
            }else{
                this.form.logics.splice(index,1,data)
            }
            this.editing.logic = null
            for(let key in this.temp){
                this.temp[key] = null
            }
        },
        deleteLogic: function(logic){
            if(confirm('Delete logic?')){
                let index = this.form.logics.findIndex((record)=>{return record.id==logic.id});
                this.form.logics.splice(index,1)
            }
        },
        newRule: function(){
            function template(id){
                let output = {
                    value: [],
                    source: {
                        type: 'questionMap',
                        input: {
                            question_id: null,
                            group_id: null,
                            choice_id: null,
                        }
                    },
                    operator: 'equals'
                }

                return output
            }
            let id = this.getID(this.editing.logic.rules)
            let logic = template(id)
            this.editing.logic.rules.push(logic)

        },
        deleteRule: function(index){
            if(confirm('Delete condition?')){
                this.editing.logic.rules.splice(index,1)
            }
        },
        groupItems: function(input){
            let language = this.language
            let questions = this.model.questions
            let question_id = input.question_id
            let output = []

            if(question_id){
                let groups = questions.filter((item)=>{return item.id==question_id})[0].groups
                for(let i=0; i<groups.length; i++){
                    let group = groups[i]
                    output.push({
                        text: `#${i+1} | ID:${group.id} - ${group.text[language]} | Type: ${group.type}`,
                        value: group.id
                    })
                }
            }
            
            return output
        },
        choiceItems: function(input){
            let language = this.language
            let questions = this.model.questions
            let question_id = input.question_id
            let group_id = input.group_id
            let output = []

            if(question_id && group_id){
                let groups = questions.filter((item)=>{return item.id==question_id})[0].groups
                let choices = groups.filter((item)=>{return item.id==group_id})[0].choices
                for(let i=0; i<choices.length; i++){
                    let choice = choices[i]
                    output.push({
                        text: `#${i+1} | ID:${choice.id} - ${choice.text[language]}`,
                        value: choice.id
                    })
                }
            }
            
            return output
        },
        resolveType: function(input){
            let questions = this.model.questions
            let question_id = input.question_id
            let group_id = input.group_id
            
            if(question_id && group_id){
                let questionIndex = questions.findIndex((item)=>{return item.id==question_id})
                if(questionIndex>=0){
                    let question = questions[questionIndex]
                    let groupIndex = question.groups.findIndex((item)=>{return item.id==group_id})
                    if(groupIndex>=0){
                        let group = question.groups[groupIndex]
                        return group.type
                    }
                }
            }
            return null

        },
        resolveChoices: function(input){
            let questions = this.model.questions
            let question_id = input.question_id
            let group_id = input.group_id
            
            if(question_id && group_id){
                let questionIndex = questions.findIndex((item)=>{return item.id==question_id})
                if(questionIndex>=0){
                    let question = questions[questionIndex]
                    let groupIndex = question.groups.findIndex((item)=>{return item.id==group_id})
                    if(groupIndex>=0){
                        let group = question.groups[groupIndex]
                        return group.choices
                    }
                }
            }
            return null

        },
        setValue: function(rule,index){
            let stringArray = this.temp.stringArray[index]
            rule.value = stringArray.split('\n')
        }
    },
    computed: {
        logics: function(){
            return this.form.logics
        },
        operators: function(){
            return [
                {
                    text: "=",
                    value: "equals"
                },
                {
                    text: "!=",
                    value: "!equals"
                },
                {
                    text: ">",
                    value: "gt"
                },
                {
                    text: "≥",
                    value: "gte"
                },
                {
                    text: "<",
                    value: "lt"
                },
                {
                    text: "≤",
                    value: "lte"
                }
            ]
        },
        questionItems: function(){
            let language = this.language
            let output = []
            let questions = this.model.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                output.push({
                    text: `#${i+1} | ID:${question.id} - ${question.body.text[language]}`,
                    value: question.id
                })
            }
            return output
        },
        unknowables: function(){
            return ['textfield','number','number-slider','calendar']
        },
        requirementItems: function(){
            return [{text:'Any',value:'anyTrue'},{text:'All',value:'allTrue'},{text:'None',value:'noneTrue'},{text:'x#',value:'xTrue'}]
        }
    },
    watch: {
        logics: {
            deep: true,
            handler: function(){
                let logics = this.logics
                console.log('logicsEditor: update',logics)
                this.$emit('update',logics)
            }
        }
    }
}
</script>

<style>

</style>