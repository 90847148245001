<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-btn small @click="newTopic">
            New Topic
          </v-btn>          
          <Topics v-if="topics" :topics="topics" @selectTopic="selectTopic" :selectedTopic="selectedTopic"/>
        </v-col>
        <v-col cols="9">
          <div v-if="selectedTopic">
            <v-btn small @click="seePosts">Manage Posts</v-btn>
            <TopicForm v-model="selectedTopic" @cancel="selectedTopic=null" @save="saveTopic" style="margin-top: 25px;"/>

          </div>
        </v-col>
        <!-- <v-col cols="6">
          <Posts v-if="posts" :posts="posts"/>
        </v-col> -->
      </v-row>
    </v-container>

    <v-dialog v-model="ui.seePosts">
      <v-card v-if="ui.seePosts">
        <v-card-text>
          <Posts :userMap="userMap" :posts="posts" @delete="deletePost" @restore="restorePost"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="ui.seePosts=false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Topics from '@/components/DiscussionForum/Topics'
import TopicForm from '@/components/DiscussionForum/Admin/TopicForm'
import Posts from '@/components/DiscussionForum/Posts'
export default {
  name: "DiscussionForumAdmin",
  created: function(){
    this.getTopics()
  },
  components: {
    Topics,
    TopicForm,
    Posts
  },
  data: function(){
    return {
      ui: {
        seePosts: false
      },
      selectedTopic: null,
      topics: [],
      posts: [],
      users: []
    }
  },
  methods: {
    getTopics: async function(){
      let response = await this.sendRequest('get','/api/admin/discussionforum/topics')
      if(response.status==200){
        this.topics = response.data.topics
        this.users = response.data.users
      }
    },
    getPosts: async function(){
      let topic = this.selectedTopic
      let response = await this.sendRequest('get',`/api/admin/discussionforum/topics/${topic.id}`)
      this.posts = []
      if(response.status==200){
        this.posts = response.data
      }
    },
    selectTopic: async function(topic){
      this.selectedTopic = null
      let self = this
      setTimeout(function(){
        self.selectedTopic = topic
      },200)
    },
    newTopic: function(){
      let languages = this.$store.getters.languages
      function template(){
        let output = {
          publish: 'N',
          title: {},
          subtitle: {},
          events: [],
          restrictedTo: [],
          tags: {},
          resources: []
        }
        for(let i=0; i<languages.length; i++){
          let language = languages[i]
          output.tags[language] = []
        }
        return output
      }
      this.selectedTopic = template()
    },
    saveTopic: async function(data){
        let response = await this.sendRequest('post',`/api/admin/discussionforum/topics`, data)
        if(response.status==200){
          this.selectedTopic = null
          await this.getTopics()
        }
    },
    seePosts: async function(){
      await this.getPosts()
      this.ui.seePosts = true
    },
    deletePost: async function(post){
      let data = JSON.stringify(post)
      data = JSON.parse(data)
      let response = await this.sendRequest('delete',`/api/admin/discussionforum/posts`, data)
      console.log('deletePost',{response, data, post})
      if(response.status==200){
        await this.getPosts()
      }
    },
    restorePost: async function(post){
      let data = JSON.stringify(post)
      data = JSON.parse(data)
      data.state="restored"
      let response = await this.sendRequest('post',`/api/admin/discussionforum/posts`, data)
      console.log('restorePost',{response, data, post})
      if(response.status==200){
        await this.getPosts()
      }
    }
  },
  computed: {
    userMap: function(){
      let output = {}
      let users = this.users
      for(let i=0; i<users.length; i++){
        let user = users[i]
        output[user.Username] = user
      }
      return output
    }    
  }
}
</script>

<style>

</style>