<template>
  <div>
    <v-container fluid>
        <v-row>
            <v-col cols="4">
                <v-btn icon small fab @click="newGroup">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
                <v-list dense>
                    <draggable v-model="groups">
                        <v-list-item :class="[{'selected':group.id==editing.group?.id}]" v-for="(group, index) in groups" :key="`group-selector-${group.id}`">
                            <v-list-item-action>
                                {{index+1}}. [{{group.id}}]
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-text-field v-model="group.text[language]" clearable language="Group Text - optional"/>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>
                                    <v-btn small icon @click="editGroup(index)">
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>

                                    <v-btn small icon color="error" @click="deleteGroup(index)">
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-col>
            <v-col>
                <GroupForm v-if="editing.group" v-model="editing.group" :groupTypes="groupTypes" :language="language" :languages="languages"/>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import GroupForm from './questionGroupForm.vue'
export default {
    name: "GroupEditor",
    components: {
        draggable,
        GroupForm
    },
    props: {
        groupTypes: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    created: function(){
        let groups = JSON.stringify(this.model)
        this.groups = JSON.parse(groups)
        if(this.groups.length==0){
            this.newGroup()
        }
    },
    data: function(){
        return {
            groups: [],
            editing: {
                group: null
            }
        }
    },
    methods: {
        getID: function(array){
            let ids = []
            for(let i=0; i<array.length; i++){
                ids.push(array[i].id)
            }

            let id=1
            for(let i=0; i<ids.length; i++){
                if(!ids.includes(id)){
                    return id
                }
                id++
            }
            return id
        },
        newGroup: function(){
            function template(id){
                let output = {
                    id,
                    text: {},
                    type: 'radio',
                    choices: [],
                    display: true,
                    disabled: false,
                    displayLogic: null,
                    disableLogic: null,
                    requirements: {
                        min: null,
                        max: null,
                        required: true
                    }
                }
                
                return output
            }

            let id = this.getID(this.groups)
            let group = template(id)
            this.groups.push(group)
            this.editGroup(this.groups.length-1)
        },
        editGroup: function(index){            
            let self = this
            this.editing.group = null
            setTimeout(function(){
                self.editing.group = self.groups[index]
            },200)
        },
        deleteGroup: function(index){
            if(confirm('Delete group?')){
                this.editing.group = null
                this.groups.splice(index, 1)
            }
        },
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        currentlyEditing: function(){
            return this.editing.group
        }
    },
    watch: {
        groups: {
            deep: true,
            handler: function(){
                this.$emit('input',this.groups)
            }
        },
        currentlyEditing: {
            deep: true,
            handler: function(){
                let group = this.editing.group
                if(group){
                    let index = this.groups.findIndex((record)=>{return record.id==group.id});
                    if(index>=0){
                        this.groups[index] = group
                    }
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.selected{
    border: 1px solid teal;
    font-weight: bold;
}
</style>